body {
  margin: 0;
}

.grab-cursor {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grab-cursor:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.fill-scrollbar::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.fill-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
 
/* Handle */
.fill-scrollbar::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 8px;
}

/* Handle on hover */
.fill-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #7AE5CA; 
}

/* Add fill custom logo font */
@font-face {
  font-family: 'fill';
  src: url(./assets/fonts/fill.ttf);
}